import { required, maxLength, minLength, requiredIf, numeric } from 'vuelidate/lib/validators';

const AddressFormValidations = {
  name: {},
  isPrimary: {},
  address: {
    required: requiredIf(function () {
      return this.required;
    }),
  },
  address2: {},
  city: {
    required: requiredIf(function () {
      return this.required;
    }),
  },
  state: {
    required: requiredIf(function () {
      return this.required;
    }),
    maxLength: maxLength(2),
    minLength: minLength(2),
  },
  zip: {
    required: requiredIf(function () {
      return this.required;
    }),
    numeric,
    maxLength: maxLength(5),
    minLength: minLength(5),
  },
  lng: {},
  lat: {},
};

export default AddressFormValidations;
