<template>
  <v-container>
    <form>
      <v-row v-if="multiple">
        <v-col cols="12" md="6">
          <v-text-field
            :disabled="disabled"
            :error-messages="handleErrors($v.form.name)"
            :readonly="readonly"
            :value="value.name"
            @blur="$v.form.name.$touch()"
            @input="handleInput"
            label="Nickname"
            required
            v-model.trim="$v.form.name.$model"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-checkbox
            :disabled="disabled"
            :value="value.isPrimary"
            @change="handlePrimary"
            label="Primary"
            required
            v-model="$v.form.isPrimary.$model"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            :disabled="disabled"
            :error-messages="handleErrors($v.form.address)"
            :readonly="readonly"
            :value="value.address"
            @blur="$v.form.address.$touch()"
            @input="handleInput"
            label="Address"
            required
            v-model.trim="$v.form.address.$model"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :value="value.address2"
            @blur="$v.form.address2.$touch()"
            @input="handleInput"
            label="Address 2"
            v-model.trim="$v.form.address2.$model"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            :disabled="disabled"
            :error-messages="handleErrors($v.form.city)"
            :readonly="readonly"
            :value="value.city"
            @blur="$v.form.city.$touch()"
            @input="handleInput"
            label="City"
            required
            v-model.trim="$v.form.city.$model"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            :disabled="disabled"
            :error-messages="handleErrors($v.form.state)"
            :readonly="readonly"
            :value="value.state"
            @blur="$v.form.state.$touch()"
            @input="handleInput"
            label="State"
            required
            v-model.trim="$v.form.state.$model"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            :disabled="disabled"
            :error-messages="handleErrors($v.form.zip)"
            :readonly="readonly"
            :value="value.zip"
            @blur="$v.form.zip.$touch()"
            @input="handleInput"
            label="Zip"
            required
            type="number"
            v-model.trim="$v.form.zip.$model"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="showLatLng" class="align-center">
        <v-col cols="12" md="3">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :value="value.lat"
            @blur="$v.form.lat.$touch()"
            @input="handleInput"
            label="Latitude"
            v-model.trim="$v.form.lat.$model"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :value="value.lng"
            @blur="$v.form.lng.$touch()"
            @input="handleInput"
            label="Longitude"
            v-model.trim="$v.form.lng.$model"
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';

import AddressFormValidations from '@/models/AddressFormValidation.model';
import { GEOCODE } from '@/store/modules/Geo/actions';
import { handleErrors } from '@/util';

const newAddress = {
  name: '',
  isPrimary: false,
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: null,
  lng: null,
  lat: null,
};

export default {
  name: 'AddressForm',
  mixins: [validationMixin],
  components: {},
  props: {
    value: {
      type: Object,
      default: () => newAddress,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    showLatLng: {
      type: Boolean,
      default: () => true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    handleErrors,
    id: null,
    form: newAddress,
  }),
  validations: {
    form: AddressFormValidations,
  },
  methods: {
    ...mapActions('geo', [GEOCODE]),
    clear() {
      this.form = newAddress;
    },
    getCurrentForm() {
      return this.$v.form;
    },
    handleInput() {
      this.$emit('input', this.$v.form.$model);
    },
    handlePrimary() {
      this.$v.form.isPrimary.$touch();
      this.$emit('input', this.$v.form.$model);
      this.$emit('handlePrimary', this.$v.form.isPrimary.$model);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value = {}) {
        this.$v.$reset();
        this.id = value.id;
        this.form = { ...newAddress, ...value };
      },
    },
  },
};
</script>
