<template>
  <v-text-field
    ref="phoneNumber"
    :value="phone"
    v-model="phone"
    :label="label || 'Phone Number'"
    :rules="combinedRules"
    maxlength="12"
    :readonly="readonly"
    :disabled="disabled"
    @keydown="handleKeyDown"
    @input="handleInput"
    :outlined="outlined"
  ></v-text-field>
</template>

<script>
export default {
  name: 'PhoneNumber',
  props: ['value', 'label', 'readonly', 'disabled', 'rules', 'outlined'],
  data() {
    return {
      phone: '',
    };
  },
  computed: {
    combinedRules() {
      if (this.rules)
        return [
          ...this.rules,
          (v) => this.validatePhoneNumberLength(v) || 'Invalid phone number length. It should be 7 or 10 digits.',
        ];
      return [(v) => this.validatePhoneNumberLength(v) || 'Invalid phone number length. It should be 7 or 10 digits.'];
    },
  },
  created() {
    this.phone = this.value;
  },
  methods: {
    handleKeyDown(event) {
      const allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
      const input = event.key;

      if (!/^\d$/.test(input) && !allowedKeys.includes(input)) {
        event.preventDefault();
      }
    },
    handleInput() {
      this.phone = this.phone ? this.phone.replace(/\D/g, '') : '';
      const phoneNumber = this.phone;
      let formattedPhoneNumber = '';

      if (phoneNumber.length === 7) {
        formattedPhoneNumber = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3)}`;
      } else if (phoneNumber.length === 10) {
        formattedPhoneNumber = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6)}`;
      } else {
        formattedPhoneNumber = phoneNumber;
      }

      this.phone = formattedPhoneNumber;
      this.$emit('input', phoneNumber);
    },
    validatePhoneNumberLength(value) {
      const phoneNumber = value ? value.replace(/\D/g, '') : '';
      return phoneNumber.length === 7 || phoneNumber.length === 10;
    },
    validate() {
      this.$refs['phoneNumber'].validate(true);
    },
    resetValidation() {
      this.$refs['phoneNumber'].resetValidation();
    },
  },
  watch: {
    value() {
      this.phone = this.value;
    },
    phone() {
      this.handleInput();
    },
  },
};
</script>
